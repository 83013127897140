import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { responseTypes } from '../../../constants/form';
import { useLearnerContext } from '../../../context/LearnerContext';

const FormPreview = () => {
  const { getSelectedForm, changeSelectedForm } = useLearnerContext();
  const [form, setForm] = useState(null);
  const { t, i18n } = useTranslation();

  const answerQuestion = (
    e,
    question,
    questionIndex,
    isMultipleAnswers = false
  ) => {
    const answer = e.target.value + '';

    setForm((prevForm) => {
      const updatedContent = [...prevForm.content];
      const updatedAnswer = prevForm.content[questionIndex];

      if (isMultipleAnswers) {
        if (!updatedContent[questionIndex]?.answer) {
          updatedContent[questionIndex] = {
            ...updatedAnswer,
            answer: [answer],
          };

          return { ...prevForm, content: updatedContent };
        }

        const index = updatedContent[questionIndex]?.answer.indexOf(answer);
        if (index !== -1) {
          updatedContent[questionIndex]?.answer.splice(index, 1);
          return { ...prevForm, content: updatedContent };
        }

        updatedContent[questionIndex] = {
          ...updatedAnswer,
          answer: [...updatedContent[questionIndex]?.answer, answer],
        };
        return { ...prevForm, content: updatedContent };
      }

      updatedContent[questionIndex] = { ...updatedAnswer, answer: answer };
      return { ...prevForm, content: updatedContent };
    });
  };

  useEffect(() => {
    const newForm = getSelectedForm();
    setForm(newForm);
    console.log(newForm);
    
    // Set language based on form's language if available
    if (newForm?.language?.code) {
      i18n.changeLanguage(newForm.language.code);
    }
  }, []);

  return (
    <Grid
      spacing={2}
      display={'flex'}
      flexDirection={'column'}
      className="container-shrinked"
    >
      {form ? (
        <Box sx={{ textAlign: 'center' }} className="forms-wrapper">
          <form className="form-answers-list">
            <div className="form-title">
              <h1>{form.title}</h1>
              <pre className="form-description">{form.description}</pre>
              <p className="form-required">* Required</p>
            </div>
            {form?.content &&
              form?.content.map((question, questionIndex) => (
                <div
                  key={questionIndex}
                  style={{ textAlign: 'left', marginBottom: '20px' }}
                  className="form-answers-wrapper"
                >
                  <div>
                    {question.question}{' '}
                    {question.required && (
                      <span style={{ color: 'red' }}>*</span>
                    )}{' '}
                    ({question.points} point(s))
                    <br />
                    {question.responseType === responseTypes.TEXT.value && (
                      <TextField
                        multiline
                        onChange={(e) =>
                          answerQuestion(e, question, questionIndex)
                        }
                        variant="standard"
                        style={{
                          width: '100%',
                        }}
                      />
                    )}
                    {question.responseType ===
                      responseTypes.MULTIPLE_CHOICE.value &&
                      [...new Set(question.options)].map((option, optionIndex) => (
                        <div key={optionIndex}>
                          <FormControlLabel
                            key={optionIndex}
                            control={
                              <Checkbox
                                value={option}
                                id={`question-${questionIndex}-option-${optionIndex}`}
                                name="radio-buttons"
                                onChange={(e) =>
                                  answerQuestion(
                                    e,
                                    question,
                                    questionIndex,
                                    true
                                  )
                                }
                              />
                            }
                          />
                          <label
                            htmlFor={`question-${questionIndex}-option-${optionIndex}`}
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    {question.responseType === responseTypes.CHECKBOX.value && (
                      <FormControl>
                        <RadioGroup name={`question-${questionIndex}`}>
                          { [...new Set(question.options)].map((option, optionIndex) => (
                            <div key={optionIndex}>
                              <FormControlLabel
                                key={optionIndex}
                                control={
                                  <Radio
                                    value={option}
                                    id={`question-${questionIndex}-option-${optionIndex}`}
                                    onChange={(e) =>
                                      answerQuestion(
                                        e,
                                        question,
                                        questionIndex,
                                        false
                                      )
                                    }
                                  />
                                }
                              />
                              <label
                                htmlFor={`question-${questionIndex}-option-${optionIndex}`}
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {question.responseType ===
                      responseTypes.LINEAR_SCALE.value && (
                      <div className="linear-wrapper">
                        <div className="form-range-labels">
                          {Array.from(
                            { length: question.linear_scale_size || 10 },
                            (_, i) => i + 1
                          ).map((num) => (
                            <span
                              key={num}
                              style={{
                                width: '30px',
                                textAlign: 'center',
                              }}
                            >
                              {num}
                            </span>
                          ))}
                        </div>
                        <ToggleButtonGroup
                          exclusive
                          value={question?.answer && +question.answer}
                          onChange={(e) =>
                            answerQuestion(e, question, questionIndex)
                          }
                          aria-label="linear scale"
                        >
                          {Array.from(
                            { length: question.linear_scale_size || 10 },
                            (_, i) => i + 1
                          ).map((num) => (
                            <ToggleButton key={num} value={num}>
                              {/* {num} */}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </form>

          <Button
            variant="contained"
            color="primary"
            disabled={true}
            sx={{
              display: 'inline-block',
              backgroundColor: '#66DEAE',
            }}
          >
            {t("Submit")}
          </Button>
        </Box>
      ) : (
        t("Oops, you haven't selected a form to preview")
      )}
    </Grid>
  );
};

export default FormPreview;
