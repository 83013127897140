import { BASE_URL } from '../constants/config';
import axios from 'axios';
import { getUTCOffset } from '../helpers/dates';

export const FORMS_ENDPOINTS = {
  forms: `${BASE_URL}api/forms`,
  complete: `${BASE_URL}api/forms/complete`,
  getToPass: `${BASE_URL}api/forms/g`,
  exportFormById: (formId) => `${BASE_URL}api/forms/${formId}/export?utc=${getUTCOffset()}`,
  crossCopy: `${BASE_URL}api/forms/cross_copy`,
};

const token = localStorage.getItem('authorization');

class Forms {
  async getAll() {
    const response = await axios.get(FORMS_ENDPOINTS.forms, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async getById(id) {
    const response = await axios.get(`${FORMS_ENDPOINTS.forms}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async updateForm(id, form) {
    const formData = {
      ...form,
      language: form.language || undefined
    };

    const response = await axios.put(`${FORMS_ENDPOINTS.forms}/${id}`, formData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async completeForm(id, answers) {
    const response = await axios.post(
      `${FORMS_ENDPOINTS.complete}/${id}`,
      {
        data: answers,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async getByIdToComplete(id) {
    const response = await axios.get(`${FORMS_ENDPOINTS.getToPass}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async createForm(form) {
    const formData = {
      ...form,
      language: form.language || undefined
    };

    const response = await axios.post(FORMS_ENDPOINTS.forms, formData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async crossCopyForm(payload) {
    const response = await axios.post(FORMS_ENDPOINTS.crossCopy, payload, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async deleteForm(id) {
    const response = await axios.delete(`${FORMS_ENDPOINTS.forms}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async exportFormById(id) {
    const response = await axios.get(`${FORMS_ENDPOINTS.exportFormById(id)}`, {
      responseType: 'blob',
      headers: {
        authorization: token,
      },
    });

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    const filename = response.headers.filename;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    return response;
  }
}

export const FormsService = new Forms();
