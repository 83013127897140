import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormControlLabel, TextField } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from '../../services/app.utils';
import { LearnersService } from '../../services/learners';
import { BASE_URL } from '../../constants/config';
import useStore from '../../store/store';
import { downloadImageBase64 } from '../../helpers/company';
import { CompanyServices } from '../../services/companies';
import { useTranslation } from 'react-i18next';

// Translations moved to translations.json file

const Welcome = () => {
  const [response, setResponse] = useState([]);
  const { id } = useParams();
  const [selectedTrainingIds, setSelectedTrainingIds] = useState([]);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = useState('');
  const [language, setLanguage] = useState('fr'); // Default language
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { setSnackbarContent, companyLogo, setCompanyLogo } = useStore(
    (state) => state
  );

  // Extract and log language parameter from URL
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const langParam = urlParams.get('lang');
    
    if (langParam) {
      // Only set if it's a supported language
      if (['fr', 'en', 'pl', 'pt'].includes(langParam)) {
        setLanguage(langParam);
        i18n.changeLanguage(langParam);
        
        // Set document language for accessibility
        document.documentElement.lang = langParam;
      } else {
        // Default to French for unsupported languages
        setLanguage('fr');
        i18n.changeLanguage('fr');
        document.documentElement.lang = 'fr';
      }
    } else {
    }
  }, [location.search, i18n]);

  const getBroadcastByLearner = async () => {
    try {
      const response = await LearnersService.welcomeLearner(id);
      setResponse(response.data.data);

      const containedTraining = response?.data?.data[0];

      if (!companyLogo && response && containedTraining?.company_id) {
        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          containedTraining.company_id,
          setCompanyLogo
        );
      }
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', t('errorMessage'));
    }
  };
  useEffect(() => {
    getBroadcastByLearner();
  }, []);

  const toggleTrainingSelection = (trainingName) => {
    if (selectedTrainingIds.includes(trainingName)) {
      setSelectedTrainingIds(
        selectedTrainingIds.filter((id) => id !== trainingName)
      );
    } else {
      setSelectedTrainingIds([...selectedTrainingIds, trainingName]);
    }
  };

  const handleUnsubscribeConfirm = async () => {
    try {
      const data = {
        learner_id: id,
        broadcast_id_list: selectedTrainingIds,
      };
      await axios.post(`${BASE_URL}api/learners/unsubscribe`, data);
      const new_broadcasts = response.filter(
        (f) => !selectedTrainingIds.includes(String(f._id))
      );
  
      setResponse(new_broadcasts);
  
      setSelectedTrainingIds([]);
      setUnsubscribed(false);
      setUnsubscribeReason('');
      setSnackbarContent(
        'success',
        t('successMessage')
      );
    } catch(e) {
      console.error(e);
      setSnackbarContent('error', t('errorMessage'));
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await LearnersService.unsubscribeLearner(id, unsubscribeReason);

      setUnsubscribeReason('');
      setUnsubscribed(false);
      navigate('/thanks');
    } catch (error) {
      console.error(error);
      setSnackbarContent('error', t('errorMessage'));
    }
  };

  const formatTrainingNames = (trainings) => {
    return trainings.map((training) => (
      <div key={training.id}>{training.name}</div>
    ));
  };

  const formatTrainingStartDates = (trainings) => {
    return trainings.map((training) => {
      // Format date according to the language
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      
      // Use language-specific date formatting
      const formattedDate = new Date(training.date).toLocaleDateString(
        language === 'fr' ? 'fr-FR' :
        language === 'en' ? 'en-GB' :
        language === 'pl' ? 'pl-PL' :
        language === 'pt' ? 'pt-PT' : 'fr-FR',
        options
      );
      
      const formattedTime = new Date(training.date).toLocaleTimeString(
        language === 'fr' ? 'fr-FR' :
        language === 'en' ? 'en-GB' :
        language === 'pl' ? 'pl-PL' :
        language === 'pt' ? 'pt-PT' : 'fr-FR',
        {
          hour: '2-digit',
          minute: '2-digit',
        }
      );
      
      return (
        <div key={training.id}>
          {`${t('startsOn')} ${formattedDate} ${t('at')} ${formattedTime}`}
        </div>
      );
    });
  };

  return (
    <div>
      {response.length === 0 ? (
        <Typography variant="body1" marginTop="50px">
          {t('noTrainings')}
        </Typography>
      ) : (
        <div>
          <Typography variant="h5" marginTop="30px" align="left">
            {t('unsubscribeTitle')}
          </Typography>
          <Typography variant="body1" marginBottom="10px" align="left">
            {t('selectUnsubscribe')}
          </Typography>
          <Table>
            <TableHead>
              <TableRow className="green-border">
                <TableCell></TableCell>
                <TableCell align="center">{t('name')}</TableCell>
                <TableCell align="center">{t('training')}</TableCell>
                <TableCell align="center">{t('startDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.map((broadcast, index) => (
                <TableRow key={index} className="green-border">
                  <TableCell align="center" className="green-border">
                    <Checkbox
                      checked={selectedTrainingIds.includes(broadcast._id)}
                      onChange={() => toggleTrainingSelection(broadcast._id)}
                    />
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast.name}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {formatTrainingNames(broadcast.trainings)}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {formatTrainingStartDates(broadcast.trainings)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
              onClick={handleUnsubscribeConfirm}
              disabled={selectedTrainingIds.length === 0}
            >
              {t('unsubscribeButton')}
            </Button>
          </div>
          <Typography variant="h5" marginTop="30px" align="left">
            {t('accountDeletionTitle')}
          </Typography>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unsubscribed}
                    onChange={() => setUnsubscribed(!unsubscribed)}
                  />
                }
                label={t('unsubscribeFromDatabase')}
              />
            </div>
            {unsubscribed && (
              <div>
                <Typography variant="body1" align="left">
                  {t('unsubscribeReason')}
                </Typography>
                <TextField
                  multiline
                  minRows={3}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  autoComplete='off'
                  value={unsubscribeReason}
                  onChange={(e) => setUnsubscribeReason(e.target.value)}
                />
              </div>
            )}
            <div style={{ marginTop: '20px', display: 'flex' }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleUnsubscribe}
                disabled={!unsubscribed}
              >
                {t('deleteAccount')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Welcome;
