import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { LinkService } from '../../services/link';

const Links = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    const getLinks = async (link) => {
      try {
        const response = await LinkService.getLink(link);

        let redirectLink = response.data.redirectLink.source;
        const prefix = 'https://';

        if (!redirectLink.includes(prefix)) {
          redirectLink = prefix + redirectLink;
        }

        // Preserve any query parameters from the current URL
        const currentQueryParams = new URLSearchParams(location.search).toString();
        
        // Check if the redirect link already has query parameters
        const hasQueryParams = redirectLink.includes('?');
        
        // If original URL already has query params, don't add duplicates
        if (!hasQueryParams && currentQueryParams) {
          redirectLink = `${redirectLink}?${currentQueryParams}`;
        } else if (hasQueryParams && currentQueryParams) {
          // If original URL has query params and we have new ones, append with &
          redirectLink = `${redirectLink}&${currentQueryParams}`;
        }

        console.log('Redirecting to:', redirectLink);
        
        setIsRedirected(true);
        window.location.replace(redirectLink);
      } catch (error) {
        console.error('Error redirecting:', error);
      }
    };
    
    if (id && isRedirected === false) getLinks(id);
  }, [id, isRedirected, location.search]);

  return (
    <Box className="files-wrapper">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
};

export default Links;
