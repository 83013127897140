import React, { useEffect, useState } from 'react';
import './Form.scss';

import {
  Box,
  DialogActions,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormsService } from '../../services/forms';

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import FormTable from './components/FormTable';
import { useLearnerContext } from '../../context/LearnerContext';
import useStore from '../../store/store';
import CreateEditForm from './CreateEditForm';
import { actionTypes } from '../../constants/types';
import TypographyUI from '../../components/TypographyUI';
import { ROLES } from '../../constants/auth';
import { getCompanyIndex } from '../../helpers';

function Form() {
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const { selectedOrganizationId, setSnackbarContent } = useStore(
    (store) => store
  );

  const { organization } = useLearnerContext();
  const role = useStore((state) => state.userRole);
  const [selectedOrganizationToCopy, setSelectedOrganizationToCopy] =
    useState(null);

  const { getForms, allForms } = useLearnerContext();
  const { changeSelectedForm } = useLearnerContext();

  const [formToCopy, setFormToCopy] = useState(null);
  const [clonedFormName, setClonedFormName] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTogglePreview = (form = null) => {
    changeSelectedForm({
      title: form?.title,
      description: form?.description,
      content: form?.content,
      language: form?.language
    });
    window.open('/form-preview', '_blank');
  };

  const getAllForms = async () => {
    await getForms();
    setLoaderVisible(false);
  };

  const deleteForm = (id) => {
    setFormToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const confirmDeleteForm = async () => {
    if (formToDelete) {
      await FormsService.deleteForm(formToDelete);
      getAllForms();
    }
    handleCloseDeleteConfirmation();
  };

  const openCopyDialog = (form) => {
    setFormToCopy(form);
    setClonedFormName(`Copie de ${form.title}`);
  };

  const closeFormCopyDialog = () => {
    setFormToCopy(null);
    setClonedFormName('');
    resetCompanyToCopy();
  };

  const cloneForm = async (form) => {
    const newForm = {
      title: clonedFormName,
      company_id: selectedOrganizationId,
      description: form.description,
      language: form.language,
      content: form.content.map((question) => {
        if (question._id) delete question._id;
        return question;
      }),
    };

    if (
      role === ROLES.ADMIN &&
      selectedOrganizationToCopy._id !== selectedOrganizationId
    ) {
      try {
        const payload = {
          form_id: formToCopy._id,
          company_id: selectedOrganizationToCopy._id,
          title: clonedFormName,
        };
        await FormsService.crossCopyForm(payload);
        closeFormCopyDialog();
        getAllForms();
        resetCompanyToCopy();
        setSnackbarContent('success', 'Formulaire copié avec succès');
      } catch (e) {
        console.error(e);
        setSnackbarContent('error', e?.response?.data?.message || 'Error');
      }

      return;
    }

    try {
      await FormsService.createForm(newForm);
      closeFormCopyDialog();
      getAllForms();
      resetCompanyToCopy();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const changeCopmanyForCopy = (event) => {
    const selectedCompany = organization.find(
      (org) => org.name === event.target.value.name
    );

    setSelectedOrganizationToCopy(selectedCompany);
  };

  const resetCompanyToCopy = () => {
    const company = organization.find(
      (org) => org._id === selectedOrganizationId
    );

    setSelectedOrganizationToCopy(company);
  };

  useEffect(() => {
    if (!selectedOrganizationToCopy && organization.length) {
      const companyToSelect = organization.find(
        (org) => org._id === selectedOrganizationId
      );

      setSelectedOrganizationToCopy(companyToSelect);
    }
  }, [organization.length]);

  useEffect(() => {
    getAllForms();
  }, []);
  if (isLoaderVisible) return <></>;

  return (
    <>
      <Grid display={'flex'} flexDirection={'column'}>
        <TypographyUI variant="h6" align="left" marginBottom="5px">
          Formulaires
        </TypographyUI>

        <Box
          sx={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            label="Recherche"
            autoComplete="off"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginRight: '10px' }}
          />
          <CreateEditForm type={actionTypes.create} />
        </Box>

        <FormTable
          allForms={allForms}
          searchTerm={searchTerm}
          deleteForm={deleteForm}
          handleTogglePreview={handleTogglePreview}
          openCopyDialog={openCopyDialog}
        />
      </Grid>
      <Dialog open={formToCopy}>
        <DialogTitle>
          Dupliquer la micro-formation "{formToCopy?.title}"
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={clonedFormName}
            fullWidth
            variant="standard"
            onChange={(e) => setClonedFormName(e.target.value)}
          />

          {role === ROLES.ADMIN && (
            <FormControl
              className="full-width"
              variant="standard"
              sx={{ minWidth: 120, marginTop: 5 }}
            >
              <Select
                id="organizations-form-select"
                value={
                  organization.length
                    ? organization[
                        getCompanyIndex(
                          selectedOrganizationToCopy?._id,
                          organization
                        )
                      ]
                    : ''
                }
                onChange={changeCopmanyForCopy}
                label="Organizations"
              >
                {organization.map((org) => (
                  <MenuItem key={org.name} value={org}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeFormCopyDialog}
            variant="outlined"
            color="error"
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => cloneForm(formToCopy)}
          >
            Clone
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer ce formulaire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDeleteConfirmation}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteForm}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Form;
