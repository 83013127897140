import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, CircularProgress } from '@mui/material';
import { FlagEmoji } from 'react-international-phone';
import { LanguageService } from '../../services/languages';

// Function to map language codes to country codes for flags
const getCountryCode = (langCode) => {
  // Special case: map 'en' to 'gb' for the British flag
  if (langCode === 'en') return 'gb';
  
  // For all other languages, use the original language code from the API
  return langCode;
};

const LanguageFlag = ({ countryCode }) => {
  return (
    <Box sx={{ 
      width: '24px', 
      height: '16px',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center' 
    }}>
      <FlagEmoji iso2={countryCode} />
    </Box>
  );
};

const LanguageSelector = ({ 
  selectedLanguage, 
  onChange,
  size = 'medium',
  label = 'Language',
  sx = {},
  onLanguageDataChange = () => {},
  disabled = false
}) => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        setLoading(true);
        const activeLanguages = await LanguageService.getActiveLanguages();
        
        const languageOptions = activeLanguages.map(lang => ({
          value: lang.code,
          label: lang.name,
          countryCode: getCountryCode(lang.code),
          ...lang
        }));
        
        setLanguages(languageOptions);
        
        const languageDataMap = languageOptions.reduce((acc, lang) => {
          acc[lang.code] = lang;
          return acc;
        }, {});
        onLanguageDataChange(languageDataMap);
        
        // Set French as default language if available
        if (!selectedLanguage && languageOptions.length > 0 && onChange) {
          const frenchOption = languageOptions.find(option => option.value === 'fr');
          if (frenchOption) {
            onChange({ target: { value: 'fr' } }, frenchOption);
          }
        }
      } catch (err) {
        console.error('Failed to fetch languages:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
    
  }, [onChange, onLanguageDataChange, selectedLanguage]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  // If no languages available, show a fallback message
  if (languages.length === 0) {
    return <div>No languages available</div>;
  }

  return (
    <FormControl sx={{ 
      minWidth: 120,
      width: { xs: '100%', md: size === 'small' ? 'auto' : '100%' },
      ...sx
    }}>
      <InputLabel id="language-select-label">{label}</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={selectedLanguage || ''}
        label={label}
        onChange={(event) => {
          const selectedLang = languages.find(lang => lang.value === event.target.value);
          onChange(event, selectedLang);
        }}
        size={size}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              position: 'absolute',
              zIndex: 1001,
              width: size === 'small' ? 'auto' : '100%',
              maxWidth: 'none'
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          slotProps: {
            paper: {
              sx: {
                transform: 'none !important',
                position: 'fixed'
              }
            }
          }
        }}
        renderValue={(selected) => {
          const selectedOption = languages.find(option => option.value === selected);
          if (!selectedOption) return '';
          
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LanguageFlag countryCode={selectedOption.countryCode} />
              {selectedOption.label}
            </Box>
          );
        }}
      >
        {languages.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LanguageFlag countryCode={option.countryCode} />
              {option.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector; 